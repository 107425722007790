import { Route, Switch } from "react-router";
import "./App.css";
import About from "./screens/About/About";
import Contact from "./screens/Contact/Contact";
import Home from "./screens/Home/Home";
import Projects from "./screens/Projects/Projects";

function App() {
  return (
    <div className="App">
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/connect">
          <Contact />
        </Route>
        <Route path="/projects">
          <Projects />
        </Route>
        <Route path="/about">
          <About />
        </Route>
      </Switch>
    </div>
  );
}

export default App;
