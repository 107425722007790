import React from "react";
import Burger from "./Burger";
import "./Header.css";

function Header(props) {
  return (
    <div className="header">
      <Burger />
    </div>
  );
}

export default Header;
