import "./About.css";
import Layout from "../../components/shared/Layout/Layout";

function About(props) {
  return (
    <Layout>
      <div className="about-container">
        <div className="page-title">About Me</div>
        <div className="about-me">
          <div className="section-header">Software</div>
          <div className="my-stack">
            My Stack: JavaScript, React, Ruby/Rails, Express, C, PostgreSQL,
            MongoDB, HTML, CSS
          </div>
          <p className="about-paragraph">
            I am a solutions driven software engineer. I have a strong drive to
            learn to skills, and I am continually working to be a better
            developer. To me, nothing is more fun than having 45 tabs
            open-reading every resourse possible to find a solution to a complex
            problem I have never seen before. ...WORK IN PROGRESS
          </p>
          <div className="section-header">Not Software</div>
          <div className="sub-header">Running</div>
          <p className="about-paragraph">
            Before embarking on my career in the tech industry, I had a
            successful 8 year career as a professional track and field
            athlete-running for NIKE. My career included a top 15 world ranking
            for 5 consecutive years, a world championship finalist, and two
            NACAC gold medals. While I no longer compete professionally ...WORK
            IN PROGRESS
          </p>
          <div className="sub-header">Life</div>
          <p className="about-paragraph">
            When not coding or running, I enjoy spending time with my family or
            working on a hobby. ...WORK IN PROGRESS
          </p>
        </div>
      </div>
    </Layout>
  );
}

export default About;
