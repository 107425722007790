import Layout from "../../components/shared/Layout/Layout";
import { Link } from "react-router-dom";
import "./Home.css";

function Home(props) {
  return (
    <Layout>
      <div className="title">
        <h1>Andy Bayer</h1>
        <h3>|Full Stack Developer|</h3>
        <div className="homepage-buttons">
          <a
            href="https://resume.creddle.io/resume/jcgq7iqy5q2"
            rel="noopener noreferrer"
            target="_blank"
          >
            <button className="homepage-button">Resume</button>
          </a>
          <a
            href="mailto:andy.brogan.bayer@gmail.com"
            rel="noopener noreferrer"
            target="_blank"
          >
            <button className="homepage-button">Connect</button>
          </a>
          <Link to="/about">
            <button className="homepage-button">About Me</button>
          </Link>
          <Link to="/projects">
            <button className="homepage-button">My Work</button>
          </Link>
        </div>
      </div>
    </Layout>
  );
}

export default Home;
