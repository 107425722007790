import Layout from "../../components/shared/Layout/Layout";
import "./Contact.css";

function Contact(props) {
  return (
    <Layout>
      <div className="contact-container">
        <div className="page-title">Connect With Me</div>
        <a
          href="mailto:andy.brogan.bayer@gmail.com"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img
            src="https://i.imgur.com/DnVU9Fl.png"
            alt="gmail"
            className="icon"
          />
        </a>
        <a
          href="https://www.linkedin.com/in/andy-bayer/"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img
            src="https://i.imgur.com/azWKn12.png"
            alt="linkedin"
            className="icon"
          />
        </a>
        <a
          href="https://github.com/arbayer4"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img
            src="https://i.imgur.com/ElxNDOs.png"
            alt="github"
            className="icon"
          />
        </a>
      </div>
    </Layout>
  );
}

export default Contact;
