import React from "react";
import Layout from "../../components/shared/Layout/Layout";
import "./Projects.css";

function Projects(props) {
  return (
    <Layout>
      <div className="projects-container">
        <div className="page-title">Projects</div>
        <div className="project">
          <img
            src="https://i.imgur.com/PLoUrXd.png"
            alt="digital-toolbelt"
            className="project-pic"
          />
          <div className="project-description">
            <div className="project-text">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras nisi
              ex, congue a urna eget, hendrerit cursus nisl. Pellentesque
              pulvinar, massa at laoreet mattis, nunc quam tincidunt lacus, nec
              egestas turpis sapien at tellus. Sed iaculis augue ante, vitae
              fringilla tortor semper nec. Sed in augue a ipsum accumsan
              feugiat. Nam tempor id nisi et semper. Cras tincidunt mi libero,
              vitae sagittis libero vestibulum ac. In ante risus, scelerisque ut
              dignissim a, molestie at ex. Morbi sed arcu eget lectus mollis
              facilisis aliquam ut sapien. Quisque consequat nunc pharetra,
              pulvinar elit vel, aliquet lacus. Integer finibus tortor id
              blandit consectetur.
            </div>
            <div className="project-link-buttons">
              <a
                href="https://digital-toolbelt.netlify.app/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <button>Visit Site</button>
              </a>
              <a
                href="https://github.com/arbayer4/Digital-ToolBelt"
                rel="noopener noreferrer"
                target="_blank"
              >
                <button>View Code</button>
              </a>
            </div>
          </div>
        </div>
        <div className="project">
          <img
            src="https://i.imgur.com/YcPpGCC.png"
            alt="sunday-drivers"
            className="project-pic"
          />
          <div className="project-description">
            <div className="project-text">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras nisi
              ex, congue a urna eget, hendrerit cursus nisl. Pellentesque
              pulvinar, massa at laoreet mattis, nunc quam tincidunt lacus, nec
              egestas turpis sapien at tellus. Sed iaculis augue ante, vitae
              fringilla tortor semper nec. Sed in augue a ipsum accumsan
              feugiat. Nam tempor id nisi et semper. Cras tincidunt mi libero,
              vitae sagittis libero vestibulum ac. In ante risus, scelerisque ut
              dignissim a, molestie at ex. Morbi sed arcu eget lectus mollis
              facilisis aliquam ut sapien. Quisque consequat nunc pharetra,
              pulvinar elit vel, aliquet lacus. Integer finibus tortor id
              blandit consectetur.
            </div>

            <div className="project-link-buttons">
              <a
                href="https://sundaydriver.netlify.app/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <button>Visit Site</button>
              </a>
              <a
                href="https://github.com/arbayer4/car-app"
                rel="noopener noreferrer"
                target="_blank"
              >
                <button>View Code</button>
              </a>
            </div>
          </div>
        </div>
        <div className="project">
          <img
            src="https://i.imgur.com/TDy8vEn.png"
            alt="cooking"
            className="project-pic"
          />
          <div className="project-description">
            <div className="project-text">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras nisi
              ex, congue a urna eget, hendrerit cursus nisl. Pellentesque
              pulvinar, massa at laoreet mattis, nunc quam tincidunt lacus, nec
              egestas turpis sapien at tellus. Sed iaculis augue ante, vitae
              fringilla tortor semper nec. Sed in augue a ipsum accumsan
              feugiat. Nam tempor id nisi et semper. Cras tincidunt mi libero,
              vitae sagittis libero vestibulum ac. In ante risus, scelerisque ut
              dignissim a, molestie at ex. Morbi sed arcu eget lectus mollis
              facilisis aliquam ut sapien. Quisque consequat nunc pharetra,
              pulvinar elit vel, aliquet lacus. Integer finibus tortor id
              blandit consectetur.
            </div>
            <div className="project-link-buttons">
              <a
                href="https://nowwerecookin.netlify.app/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <button>Visit Site</button>
              </a>
              <a
                href="https://github.com/arbayer4/now_were_cooking"
                rel="noopener noreferrer"
                target="_blank"
              >
                <button>View Code</button>
              </a>
            </div>
          </div>
        </div>
        <div className="project">
          <img
            src="https://i.imgur.com/8KAamZJ.png"
            alt="carbon-neutral"
            className="project-pic"
          />
          <div className="project-description">
            <div className="project-text">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras nisi
              ex, congue a urna eget, hendrerit cursus nisl. Pellentesque
              pulvinar, massa at laoreet mattis, nunc quam tincidunt lacus, nec
              egestas turpis sapien at tellus. Sed iaculis augue ante, vitae
              fringilla tortor semper nec. Sed in augue a ipsum accumsan
              feugiat. Nam tempor id nisi et semper. Cras tincidunt mi libero,
              vitae sagittis libero vestibulum ac. In ante risus, scelerisque ut
              dignissim a, molestie at ex. Morbi sed arcu eget lectus mollis
              facilisis aliquam ut sapien. Quisque consequat nunc pharetra,
              pulvinar elit vel, aliquet lacus. Integer finibus tortor id
              blandit consectetur.
            </div>
            <div className="project-link-buttons">
              <a
                href="https://arbayer4.github.io/Carbon-Neutral-Flights/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <button>Visit Site</button>
              </a>
              <a
                href="https://github.com/arbayer4/Carbon-Neutral-Flights"
                rel="noopener noreferrer"
                target="_blank"
              >
                <button>View Code</button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Projects;
